//
// Override default variables before the import
//
$body-bg: #292929;
$body-color: rgba(255, 255, 255, 0.87);

$theme-colors: (
  "primary": #495ea0,
  "light": #7a8bd2,
  "dark": #113571,
  "green": #0F9D58,
  "yellow": #F4B400,
  "red": #DB4437,
  "blue": #4285F4,
  "purple" : #7952b3,
  "footerdark": #3d3d3d8c,
  "footerlight": #f8f9fa,
);

$box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .30);

$lead-font-weight: 400;

//
// Import Bootstrap and its default variables
//
@import '~bootstrap/scss/bootstrap.scss';

.navbar .active {
  font-weight: 600;
}
@include media-breakpoint-up(md) {
  .navbar-svg {
    align-self: center !important;
  }
 }

.navbar-spacing-top {
  padding-top: 60px;
}
@include media-breakpoint-up(md) {
  .navbar-spacing-top {
    padding-top: 75px;
  }
}

 .bd-top-section-dark {
  background: linear-gradient(170deg, #2D2D2D 50%, #292929 50%);
  background-attachment: fixed;
 }

 .bd-top-section-light {
  background: linear-gradient(170deg, #f7f5fb 50%, #fff 50%);
  background-attachment: fixed;
 }

.bd-masthead h1 {
  font-size: calc(1.525rem + 3.3vw);
  line-height: 1;
}
@include media-breakpoint-up(xl) {
  .bd-masthead h1 {
   font-size: 4rem
  }
 }
.bd-masthead .lead {
  font-size: calc(1.275rem + .3vw);
}
.bd-masthead .lead-light {
  color: rgba(0, 0, 0, 0.60);
}
.bd-masthead .lead-dark {
  color: rgba(255, 255, 255, 0.60);
}
.stroke-light {
  stroke: white;
}
.stroke-dark {
  stroke: #292929;
}

.masthead-followup-icon {
  padding: .75rem;
  background-image: linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0.01));
  border-radius: .75rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.1);
}

.content-separator {
  border-bottom: solid 1px #808080;
  padding: 3rem 0 .5rem 0;
}

.theme-light {
  background-color: white;
  color:black;
}